/* Userpage */

.userpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.userpage .userheader {
  max-width: 700px;
  width: 100%;
}

.userpage .userinfo {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  margin: 20px 0px;
  padding: 0px 0px 15px;
  color: #595959;
  overflow: hidden;
}

.userpage .userinfo .customback {
  width: 100%;
}

.userpage .userinfo .customback img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.userpage .userinfo .avatar {
  display: block;
}

.userpage .userinfo .avatar img {
  border-radius: 50%;
  margin-top: -50%;
  background-color: white;
  padding: 4px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.userpage .userinfo .registation {
  margin: 14px 0px 0px;
  font-size: 0.9em;
}

.userpage .userinfo .lastactivity {
  margin: 14px 0px 14px;
  font-size: 0.9em;
}

.userpage .userinfo .username {
  font-size: 1.9em;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0px 15px;
}

.userpage .userinfo .usertitle {
  font-size: 1em;
  padding: 0px 15px;
}

.userpage .userinfo .contester {
  margin: 5px 0px 8px;
  padding: 10px;
  border: 1px solid #31c48d;
  border-radius: 20px;
  color: #31c48d;
}

.userpage .userinfo .join {
  font-size: 1em;
  padding: 0px 15px;
}

.userpage .userinfo .registation {
  padding: 0px 15px;
}

.userpage .userinfo .lastactivity {
  padding: 0px 15px;
}

.userpage .userinfo .infoblock {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.userpage .userinfo .infoblock .info-element {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userpage .userinfo .infoblock .info-element .value {
  font-size: 1.2em;
  font-weight: 1000;
  color: #1b1e24;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 35px; /* Added to ensure consistent height same as for karma buttons */
}

.userpage .userinfo .infoblock .info-element .info-icon {
  font-size: 0.9em;
}

.userpage .userinfo .info-icon-text {
  color: #31c48d;
  margin-right: 5px;
}

.dark-mode .userpage .userinfo .info-icon-text {
  color: #1a9123;
}

.userpage .userinfo .infoblock .info-element .desc {
  font-size: 0.9em;
  color: #70737b;
}

.userpage .userinfo .infoblock .info-element.karma .value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.userpage .karmaButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #595959;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.1em;
}

.userpage .karmaButton.up:hover {
  background-color: rgba(49, 196, 141, 0.1);
  color: #31c48d !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(49, 196, 141, 0.25);
}

.userpage .karmaButton.up.chosen {
  background-color: #31c48d;
  color: white !important;
  box-shadow: 0 4px 8px rgba(49, 196, 141, 0.3);
}

.dark-mode .userpage .karmaButton {
  background-color: #2d3748;
  color: #e2e8f0;
}

.dark-mode .userpage .karmaButton.up:hover {
  background-color: rgba(26, 145, 35, 0.2);
  color: #1eb32b !important;
  box-shadow: 0 4px 8px rgba(26, 145, 35, 0.25);
}

.dark-mode .userpage .karmaButton.up.chosen {
  background-color: #1a9123;
  color: white !important;
  box-shadow: 0 4px 8px rgba(26, 145, 35, 0.3);
}

.userpage .karmaButton.down:hover {
  background-color: rgba(237, 59, 80, 0.1);
  color: #ed3b50 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(237, 59, 80, 0.25);
}

.userpage .karmaButton.down.chosen {
  background-color: #ed3b50;
  color: white !important;
  box-shadow: 0 4px 8px rgba(237, 59, 80, 0.3);
}

.userpage .editorBlock {
  padding: 10px;
  margin: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}

.userpage .editorBlock > * {
  padding: 5px 0px;
  text-align: center;
}

.userNote {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 8px auto;
}

.userNote-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #31c48d;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userNote-button:hover {
  background-color: #28a376;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.userNote-button.has-note {
  background-color: #2563eb;
}

.userNote-button.has-note:hover {
  background-color: #1d4ed8;
}

.userNote-editor {
  width: 100%;
  margin-top: 12px;
  background: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.userNote-editor textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 0px;
  border-radius: 6px;
  resize: vertical;
  font-size: 0.95em;
  line-height: 1.5;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.userNote-editor textarea:focus {
  outline: none;
  border-color: #31c48d;
  box-shadow: 0 0 0 3px rgba(49, 196, 141, 0.1);
}

.userNote-save {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #31c48d;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.userNote-save:hover {
  background-color: #28a376;
  transform: translateY(-1px);
}

.userNote-display {
  width: 100%;
  margin-top: 8px;
  padding: 12px 16px;
  background-color: #f8fafc;
  border-left: 4px solid #31c48d;
  border-radius: 6px;
  color: #4b5563;
  font-size: 0.95em;
  line-height: 1.5;
  white-space: pre-wrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* Subscriptions */
.subscriptionButton.unsubscribe {
  background-color: #eb345e !important;
}

.tagSubscriptionButton.unsubscribe {
  background-color: #eb345e !important;
}
.tagSubscriptionButton {
  font-size: 14px;
  margin: 0px 8px !important;
}

.tagBlockButton.unblock {
  background-color: #1d7a58 !important;
}
.tagBlockButton {
  font-size: 14px;
  background-color: #eb345e !important;
  margin: 0px 8px !important;
}

.blockButton.block {
  background-color: #eba234 !important;
}

.blockButtonBlock.block {
  background-color: #eba234 !important;
}

.blockButton.unblock {
  background-color: #eb345e !important;
}

.blockButtonBlock.unblock {
  background-color: #eb345e !important;
}

/* Dark mode support */
.dark-mode .userNote-editor {
  background-color: #1f2937;
}

.dark-mode .userNote-editor textarea {
  background-color: #111827;
  border-color: #374151;
  color: #e5e7eb;
}

.dark-mode .userNote-editor textarea:focus {
  border-color: #1a9123;
  box-shadow: 0 0 0 3px rgba(26, 145, 35, 0.1);
}

.dark-mode .userNote-display {
  background-color: #111827;
  border-left-color: #1a9123;
  color: #e5e7eb;
}

/* Mobile responsiveness */
@media only screen and (max-width: 640px) {
  .userNote {
    padding: 0 12px;
  }

  .userNote-button {
    justify-content: center;
  }

  .userNote-editor {
    padding: 10px;
  }

  .userNote-editor textarea {
    min-height: 100px;
  }

  .userNote-display {
    font-size: 0.9em;
    text-align: center;
  }
}

@media only screen and (max-width: 1400px) {
  .userpage .userheader {
    width: 60%;
  }
}

@media only screen and (max-width: 1210px) {
  .userpage .userheader {
    width: 70%;
  }
}

@media only screen and (max-width: 920px) {
  .userpage .userheader {
    width: 85%;
  }
}

@media only screen and (max-width: 700px) {
  .userpage .userheader {
    width: 100%;
  }
}

/* Admin delete account button */
.deleteUserButton {
  background-color: #e53e3e !important;
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteUserButton:hover {
  background-color: #c53030 !important;
}

.admin-actions {
  margin: 8px;
}

.delete-confirmation {
  background-color: #fff1f0;
  border: 1px solid #e53e3e;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  text-align: center;
  box-shadow: 0 2px 10px rgba(229, 62, 62, 0.15);
}

.dark-mode .delete-confirmation {
  background-color: #2d1a1a;
  border-color: #b91c1c;
}

.delete-confirmation p {
  margin-bottom: 16px;
  color: #e53e3e;
  font-weight: 500;
}

.dark-mode .delete-confirmation p {
  color: #f87171;
}

.delete-buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.delete-confirm,
.delete-cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-confirm {
  background-color: #e53e3e;
  color: white;
}

.delete-confirm:hover {
  background-color: #c53030;
}

.delete-cancel {
  background-color: #e2e8f0;
  color: #4a5568;
}

.dark-mode .delete-cancel {
  background-color: #2d3748;
  color: #e2e8f0;
}

.delete-cancel:hover {
  background-color: #cbd5e0;
}

.dark-mode .delete-cancel:hover {
  background-color: #4a5568;
}
